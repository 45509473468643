<template>
  <div class="relative" ref="menuActivator">
    <icon-base
      role="menuActivator"
      v-on-click-outside="closeMenu"
      @click.prevent.stop="toggleMenu"
      icon="vertical-dots"
      height="24"
      width="24"
      data-cy="menu-button"
    />
    <teleport to="#menus">
      <ul
        v-show="showMenu"
        :style="`top: ${top - 25}px; left: ${left + 15}px`"
        class="offer-dropdown-menu absolute transform -translate-x-full z-10 flex flex-col items-start justify-start bg-white shadow-md w-max text-left rounded"
        :class="{
          'translate-y-8': !openOnTop,
          '-translate-y-full': openOnTop
        }"
      >
        <li class="w-full hover:bg-blue-100">
          <button-edit-offer
            :model="model"
            :showTitle="showTitle"
            :isFundingDetails="isFundingDetails"
            hideEditButton
          />
        </li>
        <li class="w-full hover:bg-blue-100">
          <button
            @click.prevent.stop="duplicateOffer"
            class="block w-full py-2 px-4 text-left"
          >
            {{ $t("COMMON.DUPLICATE") }}
          </button>
        </li>
        <li class="w-full hover:bg-blue-100">
          <button
            :disabled="isEmailSending"
            @click.prevent.stop="sendEmail"
            class="block w-full py-2 px-4 text-left"
          >
            {{ $t("COMMON.EMAIL_OFFER") }}
          </button>
        </li>
        <li class="cursor-pointer">
          <status-menu :offer="model" />
        </li>
        <li class="cursor-pointer">
          <contact-via-menu :offer="model" />
        </li>
        <li class="w-full hover:bg-blue-100 text-red-400">
          <delete-offer-modal
            v-if="showDeleteOfferModal"
            :selectedOffers="[model.id]"
            :offers="[model]"
            :close="() => (showDeleteOfferModal = false)"
          />
          <button
            @click.prevent.stop="showDeleteOfferModal = true"
            class="block w-full py-2 px-4 text-left"
          >
            {{ $t("COMMON.DELETE") }}
          </button>
        </li>
      </ul>
    </teleport>
  </div>
</template>
<script setup lang="ts">
import type { IOffer } from "@/models/funders";
import type { PropType } from "vue";
import { ref, onMounted, onBeforeUnmount } from "vue";
import DeleteOfferModal from "@/views/deals/modals/DeleteOfferModal.vue";
import { useDeals } from "@/hooks/deals";
import { useStore } from "vuex";
import contactViaMenu from "./ContactViaMenu.vue";
import { useDropdownMenu } from "@/hooks/menus";
import StatusMenu from "./StatusMenu.vue";
import { generateDuplicateOfferPayload } from "@/helpers/deals";

const props = defineProps({
  model: {
    type: Object as PropType<IOffer>,
    required: true
  },
  showTitle: {
    type: Boolean,
    default: true
  },
  isFundingDetails: {
    type: Boolean,
    default: false
  }
});

const { dispatch } = useStore();
const { activeDeal } = useDeals();

const isEmailSending = ref(false);
const openOnTop = ref(false);
const showDeleteOfferModal = ref(false);
const scrollable = "dealsWrapper";

const { showMenu, toggleMenu, closeMenu, top, left, menuActivator } =
  useDropdownMenu(scrollable, props.model.product_type);

const calculateMenuPosition = () => {
  const windowInnerHeight = window.innerHeight;
  const menuActivatorBottom =
    menuActivator.value?.getBoundingClientRect()?.bottom || 0;

  if (windowInnerHeight - menuActivatorBottom < 250) {
    openOnTop.value = true;
    return;
  }
  openOnTop.value = false;
};

onMounted(() => {
  calculateMenuPosition();
  document
    .getElementById(scrollable)
    ?.addEventListener("scroll", calculateMenuPosition);
});

onBeforeUnmount(() => {
  document
    .getElementById(scrollable)
    ?.removeEventListener("scroll", calculateMenuPosition);
});

const sendEmail = async (): Promise<void> => {
  isEmailSending.value = true;
  await dispatch("applications/sendOffersEmail", {
    applicationId: activeDeal.value.id
  });
  isEmailSending.value = false;
  closeMenu({
    afterClose: () => {
      return;
    }
  });
};

const duplicateOffer = async (): Promise<void> => {
  const isOfferSelfFunded = props.model.application.is_client_funded;
  const action = isOfferSelfFunded
    ? "applications/saveSelfFundedOffer"
    : "applications/saveOffer";

  await dispatch(action, generateDuplicateOfferPayload(props.model));

  closeMenu({
    afterClose: () => {
      return;
    }
  });
};
</script>
