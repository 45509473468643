<template>
  <lf-modal :close="close">
    <template #content>
      <div class="flex flex-1 flex-col items-center mt-8">
        <icon-base icon="delete-round" :width="125" :height="125" />
        <lf-h1>
          {{
            offersToDelete.length > 1
              ? $t("DEALS.DEAL_PROGRESS.DELETE_OFFERS")
              : $t("DEALS.DEAL_PROGRESS.DELETE_OFFER")
          }}
        </lf-h1>
        <label class="text-headline">
          {{
            offersToDelete.length > 1
              ? $t("DEALS.DEAL_PROGRESS.CONFIRM_OFFERS_DELETE")
              : $t("DEALS.DEAL_PROGRESS.CONFIRM_OFFER_DELETE")
          }}
        </label>
        <div
          class="scrollable-menu flex flex-col w-full items-center max-h-60 overflow-scroll pb-4"
        >
          <div
            class="flex w-2/3 justify-between rounded p-3 mt-8 shadow-md"
            v-for="offer in offersToDelete"
            :key="offer.id"
          >
            <label class="w-32">
              {{ offer.equipment_name || offer.funder_full_name }}
            </label>
            <div class="flex flex-1 space-x-5 justify-around">
              <label class="flex flex-1">
                {{ offer.product_type }}
              </label>
              <label for="">
                {{ getFormattedDate(offer.offer_generated_date || "") }}
              </label>
            </div>
          </div>
        </div>

        <div class="flex py-10 px-24 w-full justify-evenly">
          <outline-button class="w-56" @click="close">
            {{ $t("COMMON.CANCEL") }}
          </outline-button>
          <color-button class="w-56" type="error" @click="deleteOffers">
            {{ deleteMessage }}
          </color-button>
        </div>
      </div>
    </template>
  </lf-modal>
</template>
<script setup lang="ts">
import LfModal from "@/components/ui/Modal.vue";

import type { PropType } from "vue";
import { computed } from "vue";
import { formatDateCustom } from "@/helpers/formatting";
import { useStore } from "vuex";
import { useNotification } from "@/hooks/notifications";
import type { IOffer } from "@/models/funders";

const props = defineProps({
  offers: {
    type: Array as PropType<IOffer[]>,
    required: true
  },
  selectedOffers: {
    type: Array as PropType<string[]>,
    required: true
  },
  close: {
    type: Function as PropType<() => void>,
    required: true
  }
});

const emits = defineEmits(["offerDeleted"]);

const store = useStore();
const { showMessage } = useNotification();

const offersToDelete = computed(() => {
  return props.offers.filter((offer) =>
    props.selectedOffers.includes(offer.id)
  );
});

const getFormattedDate = (date: string) =>
  formatDateCustom(date, "MMM dd, yyyy");

const deleteMessage = computed(
  () => `Delete the Offer${props.selectedOffers.length === 1 ? "" : "s"}`
);

const deleteOffers = async () => {
  await Promise.all(
    offersToDelete.value.map((offer) => {
      return store.dispatch("applications/deleteOffer", {
        offer
      });
    })
  );
  props.close();
  await store.dispatch("applications/getOffers");

  const msgTitle = `${props.selectedOffers.length} Offer${
    props.selectedOffers.length == 1 ? "" : "s"
  } Deleted`;
  showMessage(msgTitle, "success");
  emits("offerDeleted");
};
</script>

<style scoped>
.scrollable-menu::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollable-menu {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  scroll-behavior: smooth;
}
</style>
